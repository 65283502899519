<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-tabs pills content-class="border-top" v-model="tabIndex">
          <b-tab active>
            <template #title>
              <feather-icon icon="LayersIcon"/>
              <span>Demirbaş Bilgileri</span>
            </template>
            <b-card no-body>
              <b-row>
                <b-col cols="12" md="9" lg="9">

                  <div class="d-flex pt-1">
                    <feather-icon icon="LayersIcon" size="19"/>
                    <h5 class="mb-0 mt-0 ml-50">Genel Bilgiler</h5>
                  </div>

                  <b-row class="p-1">

                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="İsim" rules="required">
                        <b-form-group label="* İsim">
                          <b-form-input trim placeholder="İsim" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Kategori" rules="required">
                        <b-form-group label="* Kategori" :state="getValidationState(validationContext)">
                          <v-select v-model="dataInfo.assetCategoryId" :options="categoryOptions" :reduce="val => val.value" :clearable="false"/>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Marka" rules="required">
                        <b-form-group label="* Marka" :state="getValidationState(validationContext)">
                          <v-select v-model="dataInfo.assetBrandId" :options="brandOptions" :reduce="val => val.value" :clearable="false"/>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Model">
                        <b-form-group label="Model">
                          <b-form-input trim placeholder="Model" v-model="dataInfo.modelName" :state="getValidationState(validationContext)"/>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Seri Numarası" rules="required">
                        <b-form-group label="* Seri Numarası">
                          <b-form-input trim placeholder="Seri Numarası" v-model="dataInfo.serialNumber" :state="getValidationState(validationContext)"/>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Sorumlu Organizasyon" rules="required">
                        <b-form-group label="* Sorumlu Organizasyon" :state="getValidationState(validationContext)">
                          <v-select v-model="dataInfo.organizationId" :options="organizationOptions" :reduce="val => val.value" :clearable="false"/>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="İşyeri" rules="required">
                        <b-form-group label="* İşyeri" :state="getValidationState(validationContext)">
                          <v-select v-model="dataInfo.workplaceId" :options="workplaceOptions" :reduce="val => val.value" :clearable="false"/>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                  </b-row>

                  <div class="d-flex">
                    <feather-icon icon="ShoppingCartIcon" size="19"/>
                    <h5 class="mb-0 ml-50">Satınalma Bilgileri</h5>
                  </div>

                  <b-row class="p-1">

                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Fatura Numarası">
                        <b-form-group label="Fatura Numarası" :state="getValidationState(validationContext)">
                          <b-form-input trim placeholder="Fatura Numarası" v-model="dataInfo.invoiceNo"/>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Fatura Tarihi">
                        <b-form-group label="Fatura Tarihi" :state="getValidationState(validationContext)">
                          <flatpickr v-model="dataInfo.invoiceDate" class="form-control" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                  </b-row>

                  <div class="d-flex">
                    <feather-icon icon="InfoIcon" size="19"/>
                    <h5 class="mb-0 ml-50">Diğer Bilgiler</h5>
                  </div>

                  <b-row class="p-1">

                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Tedarikçi">
                        <b-form-group label="Tedarikçi" :state="getValidationState(validationContext)">
                          <v-select v-model="dataInfo.assetSupplierId" :options="assetSupplierOptions" :reduce="val => val.value" :clearable="true"/>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Garanti Süresi (Yıl)">
                        <b-form-group label="Garanti Süresi (Yıl)">
                          <b-form-input trim placeholder="Garanti Süresi (Yıl)" v-model="dataInfo.warrantyPeriodValue" :state="getValidationState(validationContext)"/>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="12" lg="12">
                      <validation-provider #default="validationContext" name="Açıklama">
                        <b-form-group label="Açıklama">
                          <b-form-textarea v-model="dataInfo.description" rows="3" placeholder="Açıklama..." :state="getValidationState(validationContext)"/>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                  </b-row>

                </b-col>
                <b-col cols="12" md="3" lg="3" class="d-flex flex-wrap justify-content-center align-content-center">
                  <b-row>
                    <b-col cols="12" md="12" lg="12">
                      <b-avatar
                          rounded button
                          size="200px"
                          ref="previewEl"
                          :src="(dataInfo.imageBase64 !== null ? dataInfo.imageBase64 : getApiFile(dataInfo.imagePath))"
                          variant="primary"
                          @click="$refs.refInputEl.click()">
                        <feather-icon icon="LayersIcon" size="150" v-if="dataInfo.imageBase64 === null && dataInfo.imagePath === null"/>
                      </b-avatar>
                      <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer">
                    </b-col>
                    <b-col cols="12" md="12" lg="12" class="mt-2">
                      <b-button variant="outline-primary" type="button" @click="previewFile()">
                        <feather-icon icon="EyeIcon" size="16"/>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
          <TransactionList></TransactionList>
        </b-tabs>
        <action-buttons :back-route="'asset-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BFormTextarea, BAvatar, BTab, BTabs
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required, email} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/asset/asset/store"
import categoryModule from "@/views/asset/category/store"
import brandModule from "@/views/asset/brand/store"
import supplierModule from "@/views/asset/supplier/store"
import organizationModule from "@/views/organization/organization/store"
import workplaceModule from "@/views/organization/workplace/store"
import definitionModule from "@/views/system/definition/store"
import userModule from "@/views/organization/employee/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import {useInputImageRenderer} from "@core/comp-functions/forms/form-utils";
import flatpickr from "vue-flatpickr-component/src/component";
import TransactionList from "@/views/asset/asset/transaction/List.vue";
import Vue from "vue";

export default {
  components: {
    BTab,
    BTabs,
    BAvatar,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    ActionButtons,
    Overlay,
    vSelect,
    flatpickr,

    TransactionList,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_CATEGORY_MODULE_NAME = 'store-category'
    const STORE_BRAND_MODULE_NAME = 'store-brand'
    const STORE_ORGANIZATION_MODULE_NAME = 'store-organization'
    const STORE_WORKPLACE_MODULE_NAME = 'store-workplace'
    const STORE_SUPPLIER_MODULE_NAME = 'store-supplier'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_USER_MODULE_NAME = 'store-user'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_CATEGORY_MODULE_NAME, categoryModule)
      store.registerModule(STORE_BRAND_MODULE_NAME, brandModule)
      store.registerModule(STORE_ORGANIZATION_MODULE_NAME, organizationModule)
      store.registerModule(STORE_WORKPLACE_MODULE_NAME, workplaceModule)
      store.registerModule(STORE_SUPPLIER_MODULE_NAME, supplierModule)
      store.registerModule(STORE_USER_MODULE_NAME, userModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_CATEGORY_MODULE_NAME)
        store.unregisterModule(STORE_BRAND_MODULE_NAME)
        store.unregisterModule(STORE_ORGANIZATION_MODULE_NAME)
        store.unregisterModule(STORE_WORKPLACE_MODULE_NAME)
        store.unregisterModule(STORE_SUPPLIER_MODULE_NAME)
        store.unregisterModule(STORE_USER_MODULE_NAME)
      }
    })

    const tabIndex = ref(0)
    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      name: '',
      assetCategoryId: null,
      assetBrandId: null,
      organizationId: null,
      workplaceId: null,
      description: '',
      modelName: '',
      imageBase64: null,
      imagePath: null,
      assetSupplierId: null,
      invoiceDate: null,
      invoiceNo: '',
      warrantyPeriodValue: null,
      serialNumber: '',
      isActive: true,
    })

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'asset-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'asset-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {refFormObserver, getValidationState, resetForm} = formValidation()

    const previewEl = ref(null)
    const refInputEl = ref(null)
    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      dataInfo.value.imageBase64 = base64
    })

    const categoryOptions = ref([])
    busy.value = true
    store.dispatch('store-category/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          categoryOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const brandOptions = ref([])
    busy.value = true
    store.dispatch('store-brand/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          brandOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const organizationOptions = ref([])
    busy.value = true
    store.dispatch('store-organization/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          organizationOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const assetSupplierOptions = ref([])
    busy.value = true
    store.dispatch('store-supplier/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          assetSupplierOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const workplaceOptions = ref([])
    busy.value = true
    store.dispatch('store-workplace/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          workplaceOptions.value.push({label: value.shortTitle, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const previewFile = () => {
      if (dataInfo.value.imagePath !== null && dataInfo.value.imagePath !== '') {
        window.open(getApiFile(dataInfo.value.imagePath), '_blank');
      } else {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Fotoğraf bulunamadı!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    }

    return {
      busy,
      dataInfo,
      refFormObserver,
      categoryOptions,
      brandOptions,
      organizationOptions,
      workplaceOptions,
      assetSupplierOptions,
      previewEl,
      refInputEl,
      tabIndex,

      statusOptions,

      onSubmit,
      getValidationState,
      resetForm,
      avatarText,
      getApiFile,
      inputImageRenderer,
      previewFile,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
