<template>
  <b-tab v-if="$router.currentRoute.params.id > 0 && isVisible">
    <template #title>
      <feather-icon icon="PackageIcon"/>
      <span>Demirbaş İşlemleri</span>
    </template>

    <b-card no-body class="border mt-1 mb-0">
      <div class="m-1">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <v-select v-model="perPage" class="per-page-selector d-inline-block mx-50" :options="perPageOptions" :clearable="false"/>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Arama..."/>
              <TransactionDetail :is-transaction-new-sidebar-active.sync="isTransactionNewSidebarActive" :record-data-id="transactionRecordDataId" :debit-user-id="debitUserId" :debit-status-id="debitStatusId"></TransactionDetail>
              <b-button-toolbar justify>
                <b-button-group>
                  <b-button @click="reFetchData" variant="outline-primary" size="md">
                    <feather-icon icon="RefreshCcwIcon" size="18"/>
                    <span class="align-middle"></span>
                  </b-button>
                  <b-button @click="isTransactionNewSidebarActive = true; transactionRecordDataId=0" variant="primary" size="md">
                    <feather-icon icon="PlusCircleIcon" size="18"/>
                    <span class="align-middle"></span>
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
          ref="refListTable"
          primary-key="id"
          empty-text="Kayıt bulunamadı"
          :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
          :items="fetchList"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          responsive striped bordered hover show-empty>

        <template #cell(id)="data">
          <b-link @click="changeTransaction(data.item)" class="font-weight-bold">#{{ data.value }}</b-link>
        </template>

        <template #cell(transactionDate)="data">
          <date-column-table :data="data.value"></date-column-table>
        </template>

        <template #cell(createdOn)="data">
          <date-time-column-table :data="data.value"></date-time-column-table>
        </template>

        <template #cell(security)="data">
          <div class="text-nowrap">{{ data.value }}</div>
        </template>

        <template #cell(integrity)="data">
          <div class="text-nowrap">{{ data.value }}</div>
        </template>

        <template #cell(accessibility)="data">
          <div class="text-nowrap">{{ data.value }}</div>
        </template>

        <template #cell(assetValue)="data">
          <div class="text-nowrap">{{ data.value }}</div>
        </template>

        <template #cell(assetPrivacyClass)="data">
          <div class="text-nowrap" v-if="data.value === 1">Genel</div>
          <div class="text-nowrap" v-if="data.value === 2">Kurum İçi</div>
          <div class="text-nowrap" v-if="data.value === 3">Gizli</div>
        </template>

        <template #cell(userPicture)="data">
          <div class="text-nowrap">
            <b-media vertical-align="center" v-if="data.item.userId">
              <template #aside>
                <b-avatar size="32" :src="getApiFile(data.value)" :text="avatarText(data.item.userName)" :variant="`light-primary`"/>
              </template>
              <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.userName }}
          </span>
              <small class="text-muted">{{ data.item.userPositionName }}</small>
            </b-media>
          </div>
        </template>

        <template #cell(createdUserPicture)="data">
          <div class="text-nowrap">
            <b-media vertical-align="center" v-if="data.item.createdUserId">
              <template #aside>
                <b-avatar size="32" :src="getApiFile(data.value)" :text="avatarText(data.item.createdUserName)" :variant="`light-primary`"/>
              </template>
              <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.createdUserName }}
          </span>
              <small class="text-muted">{{ data.item.createdUserPositionName }}</small>
            </b-media>
          </div>
        </template>

        <template #cell(isCancelled)="data">
          <boolean-column-table :data="data.value"></boolean-column-table>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0 mr-1" dropleft>
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
              </template>
              <b-dropdown-item @click="changeTransaction(data.item)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">Değiştir</span>
              </b-dropdown-item>
              <b-dropdown-item @click="showFiles(data.item)">
                <feather-icon icon="FileIcon"/>
                <span class="align-middle ml-50">Dökümanlar</span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeTransaction(data.item)">
                <feather-icon icon="TrashIcon"/>
                <span class="align-middle ml-50">Çıkar</span>
              </b-dropdown-item>
            </b-dropdown>
            <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewRecord(data.item)"/>
            <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <footer-table :data-meta="dataMeta"></footer-table>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number last-number>

              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>

            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <Overlay :busy="busy"></Overlay>
      <preview :is-preview-modal.sync="isPreviewModal" :data-record.sync="dataPreviewRecord"></preview>
    </b-card>

    <b-modal v-model="documentModalShow" ref="document-modal" title="Dökümanlar" ok-title="Kapat" size="lg" @ok="documentHandleModal" centered no-close-on-backdrop no-close-on-esc ok-only>
      <b-row class="border-bottom border-top p-1">
        <b-col cols="12" md="4" lg="4" class="pl-0">
          <b-button variant="warning" @click="$refs.refInputEl.click()">
            <feather-icon icon="PlusSquareIcon" size="16"/>
            <span class="align-middle" role="button"> Yeni Döküman Ekle</span>
          </b-button>
          <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="addNewFile"
              multiple>
        </b-col>
      </b-row>

      <b-table
          ref="refListTable"
          primary-key="id"
          empty-text="Kayıt bulunamadı"
          :class="`position-relative table-sticky`"
          :items="transactionDocuments"
          :fields="documentTableColumns"
          responsive striped bordered hover show-empty>

        <template #cell(filePath)="data">
          <div class="text-nowrap">
            <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewFile(data.value)" class="mx-1"/>
          </div>
        </template>

        <template #cell(createdOn)="data">
          <date-time-column-table :data="data.value"></date-time-column-table>
        </template>

      </b-table>
    </b-modal>

  </b-tab>
</template>

<script>
import {BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab, BButtonToolbar, BButtonGroup, BButton, BLink, BBadge, BDropdown, BDropdownItem, BMedia, BAvatar, BModal} from 'bootstrap-vue'
import {FooterTable, DateTimeColumnTable, EditButtonTable, RemoveButtonTable} from '@/components/Table'
import vSelect from 'vue-select'
import Overlay from "@/components/Overlay.vue"
import Preview from "@/views/asset/asset/transaction/Preview.vue"
import {ref, watch} from '@vue/composition-api'
import useList from './useList'
import TransactionDetail from "@/views/asset/asset/transaction/Detail.vue"
import Vue from "vue"
import store from "@/store"
import router from "@/router"
import BooleanColumnTable from "@/components/Table/BooleanColumnTable.vue"
import DateColumnTable from "@/components/Table/DateColumnTable.vue"
import ActiveColumnTable from "@/components/Table/ActiveColumnTable.vue"
import {getApiFile, avatarText} from "@core/utils/filter";

export default {
  name: "TransactionList",
  components: {
    RemoveButtonTable,
    BModal,
    EditButtonTable,
    BAvatar, BMedia,
    BDropdownItem,
    BDropdown,
    BBadge,
    BLink,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab,
    ActiveColumnTable,
    DateColumnTable,
    BooleanColumnTable,
    Overlay, Preview, FooterTable, DateTimeColumnTable, vSelect, TransactionDetail
  },
  setup() {

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      isVisible,
      debitUserId,
      debitStatusId,

      fetchList,
      reFetchData,
    } = useList()

    const dataPreviewRecord = ref({})
    const isPreviewModal = ref(false)
    const previewRecord = (dataItem) => {
      dataPreviewRecord.value = dataItem
      isPreviewModal.value = true
    }

    const isTransactionNewSidebarActive = ref(false)
    const transactionRecordDataId = ref(0)

    const changeTransaction = (dataItem) => {
      transactionRecordDataId.value = dataItem.id
      isTransactionNewSidebarActive.value = true
    }

    const removeTransaction = (dataItem) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/removeTransaction', {assetId: router.currentRoute.params.id, id: dataItem.id}).then(response => {
            if (response.data.success) {
              reFetchData()
              Vue.swal({
                icon: 'success',
                title: 'Başarılı!',
                text: 'Kayıt çıkarıldı.',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    watch(isTransactionNewSidebarActive, (dataValue) => {
      if (dataValue === false) {
        reFetchData()
      }
    })

    const documentModalShow = ref(false)
    const documentTableColumns = ref([
      {key: 'name', label: 'Dosya Adı', sortable: false},
      {key: 'createdUserName', label: 'Ekleyen', sortable: false},
      {key: 'createdOn', label: 'Tarih', sortable: true},
      {key: 'filePath', label: 'Görüntüle', sortable: false},
    ])
    const transactionDocuments = ref([])
    const selectTransactionData = ref(null)

    const previewFile = (filePath) => {
      window.open(getApiFile(filePath), '_blank');
    }

    const documentHandleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      documentModalShow.value = false
    }

    const showFiles = (dataItem) => {
      selectTransactionData.value = dataItem
      store.dispatch('store/previewTransaction', {assetId: router.currentRoute.params.id, id: dataItem.id}).then(response => {
        transactionDocuments.value = response.data.data.documents
        console.log(response.data.data)
        documentModalShow.value = true
      }).catch(error => {

      }).finally(message => {

      })
    }

    const refInputEl = ref(null)
    const addNewFile = () => {
      const documents = refInputEl.value.files;

      const formData = new FormData();
      for (let i = 0; i < documents.length; i++) {
        let file = documents[i];
        formData.append('documents[' + i + ']', file);
      }

      busy.value = true
      store.dispatch('store/addTransactionDocument', {assetTransactionId: selectTransactionData.value.id, dataInfo: formData}).then(response => {
        documentModalShow.value = false

        Vue.swal({
          icon: 'success',
          title: 'Başarılı!',
          text: 'Ekleme işlemi tamamlandı.',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: error.response.data.message,
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })
    }

    return {
      busy,
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      isPreviewModal,
      dataPreviewRecord,
      isVisible,
      debitUserId,
      debitStatusId,

      isTransactionNewSidebarActive,
      transactionRecordDataId,

      documentModalShow,
      documentTableColumns,
      transactionDocuments,

      refInputEl,

      fetchList,
      reFetchData,
      previewRecord,

      changeTransaction,
      removeTransaction,

      getApiFile,
      avatarText,

      previewFile,
      documentHandleModal,
      showFiles,
      addNewFile,
    }
  }
}
</script>