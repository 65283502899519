<template>
  <b-sidebar
      no-close-on-backdrop
      shadow
      no-header
      right
      backdrop
      bg-variant="white"
      sidebar-class="sidebar-xlg"
      id="transaction-new-record-sidebar"
      :backdrop-variant="'dark'"
      :visible="isTransactionNewSidebarActive"
      v-if="isTransactionNewSidebarActive">
    <template #default="{ hide }">

      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ dataInfo.id === 0 ? 'İşlem Ekle' : 'İşlem Değiştir' }}</h5>
      </div>

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <b-row>
            <b-col cols="12" md="6" lg="6">
              <validation-provider #default="validationContext" name="İşlem Tarihi" rules="required">
                <b-form-group label="* İşlem Tarihi" :state="getValidationState(validationContext)">
                  <flatpickr v-model="dataInfo.transactionDate" class="form-control" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1, maxDate: 'today'}"/>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6" lg="6">
              <validation-provider #default="validationContext" name="Durum" rules="required">
                <b-form-group label="* Durum" :state="getValidationState(validationContext)">
                  <v-select v-model="dataInfo.assetStatusId" :options="assetStatusOptions" :reduce="val => val.value" :clearable="false" :disabled="recordDataId > 0"/>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="12" lg="12" v-if="dataInfo.assetStatusId === '04' && expireWarrantyDate !== null">
              <b-alert variant="warning" show class="mb-1">
                <div class="alert-body font-small-2">
                  <p>
                    <small class="mr-50"><span class="font-weight-bold">Bu demirbaş {{ expireWarrantyDate }} tarihe kadar garanti süresi kapsamındır.</span></small>
                  </p>
                </div>
              </b-alert>
            </b-col>

            <b-col cols="12" md="12" lg="12">
              <validation-provider #default="validationContext" name="Çalışan">
                <b-form-group label="Çalışan" :state="getValidationState(validationContext)">
                  <v-select v-model="dataInfo.userId" :options="userOptions" :reduce="val => val.value" :clearable="true">
                    <template #option="{ avatar, label }">
                      <b-avatar size="sm" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                      <span class="ml-50 align-middle"> {{ label }}</span>
                    </template>
                    <template #selected-option="{ avatar, label }">
                      <b-avatar size="sm" class="border border-white" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                      <span class="ml-50 align-middle"> {{ label }}</span>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6" lg="6" v-if="dataInfo.assetStatusId === '02'">
              <validation-provider #default="validationContext" name="Gizlilik">
                <b-form-group label="Gizlilik">
                  <b-form-input trim placeholder="Gizlilik" v-model="dataInfoSecurity" :state="getValidationState(validationContext)" type="number" min="0"/>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6" lg="6" v-if="dataInfo.assetStatusId === '02'">
              <validation-provider #default="validationContext" name="Bütünlük">
                <b-form-group label="Bütünlük">
                  <b-form-input trim placeholder="Bütünlük" v-model="dataInfoIntegrity" :state="getValidationState(validationContext)" type="number" min="0"/>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6" lg="6" v-if="dataInfo.assetStatusId === '02'">
              <validation-provider #default="validationContext" name="Erişilebilirlik">
                <b-form-group label="Erişilebilirlik">
                  <b-form-input trim placeholder="Erişilebilirlik" v-model="dataInfoAccessibility" :state="getValidationState(validationContext)" type="number" min="0"/>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6" lg="6" v-if="dataInfo.assetStatusId === '02'">
              <validation-provider #default="validationContext" name="Varlık Değeri">
                <b-form-group label="Varlık Değeri">
                  <b-form-input trim placeholder="Varlık Değeri" v-model="dataInfo.assetValue" :state="getValidationState(validationContext)" readonly/>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6" lg="6" v-if="dataInfo.assetStatusId === '02'">
              <validation-provider #default="validationContext" name="Varlık Gizlilik Sınıfı">
                <b-form-group label="Varlık Gizlilik Sınıfı" :state="getValidationState(validationContext)">
                  <v-select v-model="dataInfo.assetPrivacyClass" :options="assetPrivacyClassOptions" :reduce="val => val.value" :clearable="false" disabled="disabled"/>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="12" lg="12">
              <validation-provider #default="validationContext" name="Açıklama">
                <b-form-group label="Açıklama">
                  <b-form-textarea trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)" rows="2"/>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="12" lg="12" v-if="dataInfo.id === 0">
              <validation-provider #default="validationContext" name="Döküman Ekle">
                <b-form-group label="Döküman Ekle">
                  <b-form-file id="transactionFiles" name="transactionFiles" placeholder="Döküman seçimi yapınız..." drop-placeholder="Dökümanlarınızı sürükleyerek buraya bırakabilirsiniz..." multiple/>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <div class="d-flex mt-2">
            <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" type="submit">
              <feather-icon icon="SaveIcon" size="16"/>
              <span class="align-middle"> Kaydet</span>
            </b-button>
            <b-button @click="$emit('update:is-transaction-new-sidebar-active', false)" variant="outline-secondary">
              <feather-icon icon="XCircleIcon" size="16"/>
              <span class="align-middle" role="button"> Vazgeç</span>
            </b-button>
          </div>

        </b-form>
      </validation-observer>

      <Overlay :busy="busy"></Overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BAvatar, BCol, BRow, BAlert, BFormFile
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref, watch} from '@vue/composition-api'
import {required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import router from "@/router";
import flatpickr from "vue-flatpickr-component/src/component"
import {statusOptions} from "@core/utils/filter";
import {avatarText, getApiFile} from "@core/utils/filter"
import Vue from "vue";

export default {
  components: {
    BFormFile,
    BAlert,
    BRow,
    BCol,
    BAvatar,
    BFormTextarea,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    vSelect,
    Overlay,
    flatpickr,

    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isTransactionNewSidebarActive',
    event: 'update:is-transaction-new-sidebar-active',
  },
  props: {
    isTransactionNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    recordDataId: {
      type: Number,
      required: true,
    },
    debitUserId: {
      type: Number,
    },
    debitStatusId: {
      type: String,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)

    const expireWarrantyDate = ref(null)
    const dataInfoSecurity = ref(null)
    const dataInfoIntegrity = ref(null)
    const dataInfoAccessibility = ref(null)

    const blankRecordData = {
      id: 0,
      assetId: router.currentRoute.params.id,
      transactionDate: null,
      userId: null,
      security: null,
      integrity: null,
      accessibility: null,
      assetValue: null,
      assetPrivacyClass: null,
      assetStatusId: null,
      description: null,
      imageBase64: null,
      imagePath: null,
    }

    const dataInfo = ref(JSON.parse(JSON.stringify(blankRecordData)))
    const resetRecordData = () => {
      dataInfo.value = JSON.parse(JSON.stringify(blankRecordData))
    }

    const assetStatusOptions = ref([])
    const allAssetStatusOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'ASSET_STATUS_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        assetStatusOptions.value.push({label: value.name, value: value.key})
        allAssetStatusOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && dataInfo.value.id === 0) {
          dataInfo.value.assetStatusId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const userOptions = ref([])
    const allUserOptions = ref([])
    busy.value = true
    store.dispatch('store-user/fetchItems', {
      sortColumn: 'firstName',
      sortDesc: false,
      isActive: true,
    }).then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          if (!userOptions.value.some(e => e.value === value.id)) {
            userOptions.value.push({label: value.firstName + ' ' + value.lastName, value: value.id, avatar: value.profilePicture})
            allUserOptions.value.push({label: value.firstName + ' ' + value.lastName, value: value.id, avatar: value.profilePicture})
          }
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const onSubmit = () => {
      if (dataInfo.value.assetStatusId === '02' && (dataInfo.value.userId === null || dataInfo.value.userId === 0)) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Çalışan seçimi yapılmalıdır!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      busy.value = true

      dataInfo.value.security = dataInfoSecurity.value
      dataInfo.value.integrity = dataInfoIntegrity.value
      dataInfo.value.accessibility = dataInfoAccessibility.value

      store.dispatch('store/' + (dataInfo.value.id > 0 ? 'editTransaction' : 'addTransaction'), {assetId: router.currentRoute.params.id, dataInfo: dataInfo.value}).then(response => {
        if (dataInfo.value.id === 0 && document.getElementById('transactionFiles').files.length > 0) {
          const documents = document.getElementById('transactionFiles').files
          const formData = new FormData();
          for (let i = 0; i < documents.length; i++) {
            let file = documents[i];
            formData.append('documents[' + i + ']', file);
          }

          store.dispatch('store/addTransactionDocument', {assetTransactionId: response.data.data, dataInfo: formData}).then(response => {
            toastMessage(toast, 'success', response.data.message)
            emit('update:is-transaction-new-sidebar-active', false)
          }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
          }).finally(message => {
            busy.value = false
          })

        } else {
          toastMessage(toast, 'success', response.data.message)
          emit('update:is-transaction-new-sidebar-active', false)
        }
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRecordData)

    watch(() => props.isTransactionNewSidebarActive, (dataValue) => {
      userOptions.value = allUserOptions.value
      assetStatusOptions.value = allAssetStatusOptions.value

      if (dataValue) {
        busy.value = true
        store.dispatch('store/fetchExpireWarrantyDate', {assetId: router.currentRoute.params.id}).then(response => {
          expireWarrantyDate.value = response.data.data
        }).catch(error => {

        }).finally(message => {
          busy.value = false
        })

        if (props.recordDataId === 0) {
          resetForm()
          dataInfo.value.userId = props.debitUserId

          if (props.debitUserId !== null) {
            userOptions.value = userOptions.value.filter(item => item.value === props.debitUserId)
          }

          console.log(props.debitStatusId)
          if (props.debitStatusId === '01') {
            assetStatusOptions.value = assetStatusOptions.value.filter(item => item.value !== '01')
          } else if (props.debitStatusId === '02') {
            assetStatusOptions.value = assetStatusOptions.value.filter(item => item.value === '01' || item.value === '03' || item.value === '04')
          } else if (props.debitStatusId === '03') {
            assetStatusOptions.value = assetStatusOptions.value.filter(item => item.value === '01')
          } else if (props.debitStatusId === '04') {
            assetStatusOptions.value = assetStatusOptions.value.filter(item => item.value !== '02' && item.value !== '04')
          } else if (props.debitStatusId === '05') {
            assetStatusOptions.value = assetStatusOptions.value.filter(item => item.value === '01' || item.value === '03')
          }

        } else {
          busy.value = true
          store.dispatch('store/fetchTransaction', {assetId: router.currentRoute.params.id, id: props.recordDataId}).then(response => {
            dataInfo.value = response.data.data
            dataInfoSecurity.value = dataInfo.value.security
            dataInfoIntegrity.value = dataInfo.value.integrity
            dataInfoAccessibility.value = dataInfo.value.accessibility
          }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            emit('update:is-transaction-new-sidebar-active', false)
          }).finally(message => {
            busy.value = false
          })
        }


      } else {
        resetForm()
      }
    })

    const assetPrivacyClassOptions = [
      {label: 'Genel', value: 1},
      {label: 'Kurum İçi', value: 2},
      {label: 'Gizli', value: 3},
    ]

    watch([dataInfoSecurity, dataInfoIntegrity, dataInfoAccessibility], () => {
      dataInfo.value.assetValue = dataInfoSecurity.value * dataInfoIntegrity.value * dataInfoAccessibility.value
      if (dataInfo.value.assetValue <= 8) {
        dataInfo.value.assetPrivacyClass = 1
      } else if (dataInfo.value.assetValue <= 51) {
        dataInfo.value.assetPrivacyClass = 2
      } else if (dataInfo.value.assetValue <= 125) {
        dataInfo.value.assetPrivacyClass = 3
      } else {
        dataInfo.value.assetPrivacyClass = null
      }
    })

    return {
      busy,
      dataInfo,
      refFormObserver,
      assetStatusOptions,
      userOptions,
      dataInfoSecurity,
      dataInfoIntegrity,
      dataInfoAccessibility,
      assetPrivacyClassOptions,
      expireWarrantyDate,

      statusOptions,

      getValidationState,
      resetForm,
      onSubmit,
      avatarText,
      getApiFile
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#transaction-new-record-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

</style>
