import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils";
import router from "@/router";
import {perPageOptions} from "@core/utils/filter";

export default function useList() {
    const toast = useToast()
    const refListTable = ref(null)

    const tableColumns = [
        {key: 'id', label: '#', sortable: true, stickyColumn: true, variant: 'secondary', thStyle: {width: "1%"}},
        {key: 'transactionDate', label: 'Tarih', sortable: true},
        {key: 'userPicture', label: 'Çalışan', sortable: true},
        {key: 'security', label: 'Gizlilik', sortable: true},
        {key: 'integrity', label: 'Bütünlük', sortable: true},
        {key: 'accessibility', label: 'Erişilebilirlik', sortable: true},
        {key: 'assetValue', label: 'Varlık Değeri', sortable: true},
        {key: 'assetPrivacyClass', label: 'Varlık Gizlilik Sınıfı', sortable: true},
        //{key: 'isCancelled', label: 'İptal', sortable: true},
        {key: 'createdUserPicture', label: 'İşlemi Yapan', sortable: true},
        {key: 'createdOn', label: 'İşlem Tarihi', sortable: true},
        {key: 'assetStatusName', label: 'Durum', sortable: true},
        {key: 'actions', label: 'İşlemler', sortable: false, stickyColumn: true, tdClass: 'sticky-class', variant: 'secondary', thStyle: {width: "2%"}},
    ]

    const busy = ref(false)
    const perPage = ref(10)
    const totalCount = ref(0)
    const currentPage = ref(1)
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const isVisible = ref(true)
    const debitUserId = ref(null)
    const debitStatusId = ref(null)

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalCount.value,
        }
    })

    const reFetchData = () => {
        refListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        reFetchData()
    })

    const fetchList = (ctx, callback) => {
        busy.value = true
        store.dispatch('store/fetchTransactionList', {
            assetId: router.currentRoute.params.id,
            queryParams: {
                search: searchQuery.value,
                start: perPage.value * (currentPage.value - 1),
                length: perPage.value,
                sortColumn: sortBy.value,
                sortDesc: isSortDirDesc.value,
            },
        }).then(response => {
            callback(response.data.data)
            totalCount.value = response.data.count

            response.data.data.sort(function (a, b) {
                return b['id'] - a['id'];
            });

            if (response.data.data[0].assetStatusId === '02' && response.data.data.length > 0) {
                debitUserId.value = response.data.data[0].userId
            } else {
                debitUserId.value = null
            }

            if (response.data.data.length > 0) {
                debitStatusId.value = response.data.data[0].assetStatusId
            }

        }).catch(error => {
            if (error.response.status === 403) {
                isVisible.value = false
            } else {
                toastMessage(toast, 'danger', error.response.data.message)
            }
        }).finally(() => {
            busy.value = false
        })
    }

    return {
        tableColumns,
        perPage,
        currentPage,
        totalCount,
        dataMeta,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refListTable,
        busy,
        isVisible,
        debitUserId,
        debitStatusId,

        perPageOptions,

        fetchList,
        reFetchData,
    }
}